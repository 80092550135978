/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.AuthorizedContentContainer-module_container_1a-PE {
  display: flex;
  flex-direction: column;
  width: calc(100% - 13.125rem);
  max-width: 112.5rem;
  margin: 0 auto;
  padding: 2rem;
  padding-bottom: 1rem;
}
@media (max-width: 599px) {
  .AuthorizedContentContainer-module_container_1a-PE {
    padding: 1rem;
    width: calc(100% - 6rem);
  }
}

.AuthorizedContentContainer-module_footer_eoA5J {
  max-height: 1rem;
  font-size: 0.875rem;
  text-align: right;
  color: #36495e;
}
@media (max-width: 599px) {
  .AuthorizedContentContainer-module_footer_eoA5J {
    text-align: center;
  }
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.SingleColumnLayout-module_container_1QH2L {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: calc(100% - 1rem);
}
@media (min-width: 1200px) {
  .SingleColumnLayout-module_container_1QH2L {
    justify-content: flex-start;
  }
}

.SingleColumnLayout-module_column_3-a0x {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex: 1 1 45.625rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.RestrictedPage-module_container_NdSAw {
  background-color: #ffffff;
  padding: calc(2 * 1rem);
  border-radius: 1rem;
  text-align: center;
}

.RestrictedPage-module_header_ug_S8 {
  font-size: 2rem;
  color: #36495e;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.RestrictedPage-module_text_17eT9 {
  color: #36495e;
}

.RestrictedPage-module_icon_3r6Jz {
  display: block;
  margin: 1rem auto;
  margin-top: 0;
  width: 3rem;
  height: 3rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.FormDialog-module_form_Gq-ft {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FormDialog-module_buttonContainer_2hU05 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.GeneralMessageDialog-module_title_2_E-r {
  font-size: 1.25rem;
}

.GeneralMessageDialog-module_dialog_-0761 {
  padding: calc(2 * 1rem);
  text-align: center;
  max-width: 33.25rem;
}

.GeneralMessageDialog-module_content_3N3eO {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  padding: 1rem calc(2 * 1rem);
}

.GeneralMessageDialog-module_actions_1epn8 {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ResponseMessageDialog-module_icon_1dYxj {
  margin: 1rem auto;
  margin-top: 0;
  width: 3rem;
  height: 3rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ConfirmationDialog-module_secondaryButton_2Fl3t {
  background-color: #ffffff;
  border: 1px solid #36495e;
  color: #36495e;
  font-weight: 400;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.FormDialogButton-module_button_2EVrd {
  background-color: #16aba7;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.TableContainer-module_container_1zGDN {
  width: 100%;
  flex-grow: 1;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.FullWidthTableLayout-module_container_mNVXM {
  width: 100%;
  min-height: calc(100vh - (1rem * 4));
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.FullWidthTableLayout-module_headerContainer_1lCSV {
  justify-content: space-between;
  padding-bottom: 1rem;
  align-items: flex-end;
}

.FullWidthTableLayout-module_buttonsContainer_15ARS {
  padding-bottom: 0;
}
.FullWidthTableLayout-module_buttonsContainer_15ARS > * {
  margin: 0 calc(1rem/ 2);
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.AuthorizedContainer-module_container_2yV7W {
  background: #f0f0f0;
  min-height: 100vh;
  display: flex;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.NavigationLink-module_link_a_wEp {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 1.25rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Navigation-module_container_13vwe {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.Navigation-module_menu_2oM_k {
  font-size: 1.25rem;
}

@media (max-width: 599px) {
  .Navigation-module_text_1J__N {
    display: none;
  }
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.SideBar-module_header_23PNw {
  width: 13.125rem;
  background: #36495e;
  box-shadow: 0px 2px 4px 0px rgba(54, 73, 94, 0.5);
  height: 100vh;
}
@media (max-width: 599px) {
  .SideBar-module_header_23PNw {
    width: 6rem;
  }
}

.SideBar-module_container_1Jksr {
  min-width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.SideBar-module_logo_rIdUT {
  display: block;
  align-items: center;
  margin-bottom: 1.5rem;
}

.SideBar-module_childrenContainer_2Ndgz {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.AuthorizedLayout-module_logo_1aTkq {
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  padding: 1rem 0;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.NavigationProfile-module_link_gDgZk {
  margin-top: auto;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.TwoColumnsLayout-module_container_13_ES {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 1rem);
}
@media (min-width: 1200px) {
  .TwoColumnsLayout-module_container_13_ES {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.TwoColumnsLayout-module_main_1ZwKc,
.TwoColumnsLayout-module_right_1kViY {
  padding: 1rem;
}

.TwoColumnsLayout-module_main_1ZwKc {
  flex: 1;
  width: 100%;
  max-width: 35.25rem;
}
@media (min-width: 1200px) {
  .TwoColumnsLayout-module_main_1ZwKc {
    flex: 0 1 45.625rem;
    width: 100%;
    max-width: none;
  }
}

.TwoColumnsLayout-module_right_1kViY {
  flex: 1;
  width: 100%;
  max-width: 35.25rem;
}
@media (min-width: 1200px) {
  .TwoColumnsLayout-module_right_1kViY {
    max-width: 25.375rem;
  }
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ChangePassword-module_buttonContainer_s2929 {
  margin-top: calc(1rem * 2);
  display: flex;
  justify-content: flex-end;
}

.ChangePassword-module_successBox_1wqoB,
.ChangePassword-module_errorBox_3OM5h {
  margin-bottom: 1rem;
}

.ChangePassword-module_successBox_1wqoB {
  display: flex;
}

.ChangePassword-module_successIcon_2Xr9W {
  margin-right: 1rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ProfileSide-module_buttonIcon_1zoFy {
  margin-right: 1rem;
}

.ProfileSide-module_buttonLabel_2bzDo {
  display: flex;
  align-items: center;
  color: #36495e;
  font-size: 1rem;
}

.ProfileSide-module_toggleLabel_1KVmG {
  display: flex;
  align-items: center;
  color: #36495e;
  font-size: 1rem;
  padding: 1rem;
}

.ProfileSide-module_link_1iVAg {
  display: block;
  border-top: 0.0625rem solid #36495e;
}

.ProfileSide-module_link_1iVAg:first-child {
  border-top: 0;
  border-radius: 1rem 1rem 0 0;
}

.ProfileSide-module_link_1iVAg:last-child {
  border-radius: 0 0 1rem 1rem;
}

.ProfileSide-module_selectedLink_2Pfpy {
  background-color: #9cb7d6;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ActionIconButton-module_button__XHcc svg {
  color: #36495e;
  fill: #36495e;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.IconLink-module_link_2Vq14 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #36495e;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.DetailsDialog-module_actionsContainer_3pAQn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.DialogErrorBox-module_box_HRQsk {
  margin-bottom: 1rem;
  width: 25rem;
  max-width: 100%;
}

.DialogErrorBox-module_title_2sP68,
.DialogErrorBox-module_body_2XlEk {
  text-align: left;
  display: block;
}

.DialogErrorBox-module_title_2sP68 {
  font-weight: 700;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ExportButton-module_icon_2O-Fq {
  margin-right: calc(1rem / 2);
}