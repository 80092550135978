/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Widget-module_section_1ig6a {
  align-self: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.PageTitle-module_title_KT0fr {
  font-size: 1.5rem;
  color: #36495e;
  margin: 0;
  line-height: 2rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.MessageBox-module_container_1sWqQ {
  background-color: #36495e;
  padding: 1rem;
  border-radius: 0.25rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ErrorBox-module_container_3bmj6 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ErrorBox-module_closeIcon_xUht- {
  align-self: flex-start;
}
.ErrorBox-module_closeIcon_xUht- svg {
  max-width: 1rem;
}

.ErrorBox-module_errorMessageContainer_3tydC {
  flex: 1;
  padding: 0 1rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Inputs-module_textfield_1vdP3 {
  margin-top: 0.625rem;
}

.Inputs-module_description_32vOn {
  margin-top: 1.875rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.InnerLoader-module_wrapper_gihmk {
  position: relative;
}

.InnerLoader-module_progress_1qmz7 {
  color: #ffffff;
}

.InnerLoader-module_iconWrapper_394YV {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Buttons-module_semitransparent_-Cmlf {
  background-color: green;
  border: none;
  padding: 1rem;
  box-shadow: none;
}
.Buttons-module_semitransparent_-Cmlf:hover {
  background-color: rgba(54, 73, 94, 0.5);
  box-shadow: none;
}

.Buttons-module_emptyButton_1j3vJ {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.StandardSelectOption-module_menuItem_Y4nKd {
  align-items: flex-start;
  font-weight: 500;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.SelectInput-module_MuiFormControlRoot_ROft6 {
  margin-top: 0.625rem;
  width: 100%;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.AutocompleteInput-module_MuiAutoCompleteRoot_2eCd4 {
  width: calc(100% - 4px);
}

.AutocompleteInput-module_MuiListboxComponent_2Bonj {
  height: 10rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.IconText-module_container_1BGoo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(1rem / 2);
}

.IconText-module_iconContainer_3fscP {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.UploadImageInput-module_span_1pwti {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: calc(1rem / 2);
  align-items: center;
  margin-top: 1rem;
}

.UploadImageInput-module_fileNameSpan_1obB8 {
  margin-left: 0.625rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.DateTimeInput-module_picker_2GEzy .DateTimeInput-module_MuiDialog_2SHNq svg {
  color: #ffffff;
}

.DateTimeInput-module_input_HtrDZ {
  margin-top: 0.625rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.FormErrorBoxMessageError-module_container_3r-IV {
  text-align: left;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.FormSuccessBox-module_box_3Im3h {
  display: flex;
}

.FormSuccessBox-module_icon_25f6F {
  margin-right: 1rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.PageLoader-module_wrapper_14FZT {
  background-color: #36495e;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Dialog-module_dialogTitle_1BWgQ {
  display: flex;
  position: relative;
  flex: 0 0 1.25rem;
  align-items: center;
  justify-content: center;
}

.Dialog-module_header_Qkmgt {
  flex: 1 0 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.25rem;
  margin: 0;
  align-self: center;
  padding: 0 1.25rem;
}

.Dialog-module_dialogContent_1Fmh- {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
}

.Dialog-module_dialogActions_12HkJ {
  display: flex;
  padding-top: 1.5rem;
  flex: 0 0 2.5rem;
  justify-content: center;
}

.Dialog-module_backButton_3z0rn {
  position: absolute;
  padding: 0.75rem;
  left: -0.75rem;
  top: -0.875rem;
  fill: #36495e;
}

.Dialog-module_iconButton_67t8C {
  position: absolute;
  padding: 0.75rem;
  right: -0.75rem;
  top: -0.875rem;
  fill: #36495e;
}

.Dialog-module_dialog_1tRWI {
  padding: 1rem;
  width: 100%;
  max-width: calc(100% - 2rem);
  margin: 0;
}
@media (min-width: 600px) {
  .Dialog-module_dialog_1tRWI {
    padding: 1.5rem;
    max-width: 29.375rem;
  }
}

.Dialog-module_container_2vcu7 {
  padding: 20px 20px;
}

.Dialog-module_iconButton_67t8C {
  padding: 0.75rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.TableMessage-module_message_F6eGz {
  top: 50%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.TableLoadingOverlay-module_tableLoaderContainer_2YPfJ {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.Disclaimer-module_disclaimer_2YyQx {
  margin: 0.25rem 0;
  display: block;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.ColumnsContainer-module_container_2eLxf {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-wrap: wrap;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.AvatarInitials-module_figure_smiLI {
  display: flex;
  background-color: white;
  color: #36495e;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 50%;
  margin: 0;
  height: 1.75rem;
  width: 1.75rem;
  justify-content: center;
}

.AvatarInitials-module_span_2HePJ {
  align-self: center;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.NumberArrayInput-module_container_30J2x {
  width: 100%;
  margin-top: 1rem;
}

.NumberArrayInput-module_row_1RVz3 {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.NumberArrayInput-module_input_u1Nhg {
  flex: 1;
  margin: 0;
}

.NumberArrayInput-module_button_26vU4 svg {
  color: #36495e;
}

.NumberArrayInput-module_addButton_3wDxZ {
  margin-top: 1rem;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.TableCell-module_MuiTableCellRoot_2meSN {
  color: #36495e;
}

.TableCell-module_MuiTableCellRootHeader_2qL7y {
  font-weight: 700;
  color: #36495e;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.WidgetButton-module_button_3N0hj {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.InfoMessageBox-module_container_2l3yX {
  display: flex;
  flex-direction: row;
}

.InfoMessageBox-module_textContainer_3dJxd {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  text-align: left;
}

.InfoMessageBox-module_icon_8F0Cp {
  color: #ffffff;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.VerticalMenu-module_button_2LIDn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.VerticalMenu-module_menu_3Z13B ul {
  background-color: #36495e;
}
/* Color Pallette */
/* Typography */
/* Useful sizes */
/* z-indexes */
/* box-shadow */
/* media query variables */
.SwitchInput-module_container_SmWXS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}