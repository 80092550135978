@import '~@wave/common/lib/styles/variables';

.MuiDataGrid-root {
  background-color: $white;
}

.MuiDataGrid-iconSeperator {
  color: $light-gray;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-blue;
}

.MuiDataGrid-toolbar {
  svg {
    color: $dark-blue;
  }
  & button:hover {
    svg {
      color: $white;
    }
  }
  span.MuiButton-label {
    font-size: rem(16);
  }
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: $font-bold;
  text-align: center;
  line-height: 15px;
  white-space: normal;
  display: flex;
  align-items: center;
  overflow: unset;
  min-height: rem(56);
}

.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value='false'],
.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value='true'] {
  color: $dark-blue;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-input {
  display: none !important;
}
